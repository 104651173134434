import React, { useState } from "react";
import {
	TextField,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Box,
	Typography,
	Paper,
	Divider,
	InputAdornment,
	IconButton,
	Link,
	Alert,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import CalculateIcon from "@mui/icons-material/Calculate";
import image1 from "./converter.jpg"; // Adjust the path if needed

function App() {
	const [cgpa, setCgpa] = useState("");
	const [totalCgpa, setTotalCgpa] = useState(4);
	const [percentage, setPercentage] = useState(null);
	const [error, setError] = useState("");

	const handleCgpaChange = (event) => {
		let value = event.target.value;

		// Allow only numbers and a single dot for float values
		if (!/^\d*\.?\d*$/.test(value)) {
			return; // Exit if input is invalid
		}

		// Prevent input of values greater than total CGPA
		if (parseFloat(value) > totalCgpa) {
			setError(`CGPA cannot be greater than the total CGPA of ${totalCgpa}.`);
			return;
		}

		// Limit the input to two decimal places
		if (value.includes(".")) {
			const [integerPart, decimalPart] = value.split(".");
			if (decimalPart.length > 2) {
				value = `${integerPart}.${decimalPart.slice(0, 2)}`; // Truncate to two decimal places
			}
		}

		// Clear error and set CGPA if valid
		setError("");
		setCgpa(value);

		// Recalculate percentage if CGPA is valid
		if (!isNaN(value) && value !== "") {
			const calculatedPercentage = applyFormula(parseFloat(value), totalCgpa);
			setPercentage(calculatedPercentage);
		} else {
			setPercentage(null); // Clear percentage if input is invalid
		}
	};

	const handleTotalCgpaChange = (event) => {
		const newTotalCgpa = parseInt(event.target.value);
		setTotalCgpa(newTotalCgpa);

		// Recalculate percentage if a valid CGPA is already entered
		if (cgpa && !isNaN(cgpa)) {
			if (parseFloat(cgpa) > newTotalCgpa) {
				// Clear CGPA input if it exceeds the new total CGPA
				setCgpa("");
				setPercentage(null);
				setError(`CGPA cannot be greater than ${newTotalCgpa}.`);
			} else {
				setError("");
				const calculatedPercentage = applyFormula(
					parseFloat(cgpa),
					newTotalCgpa
				);
				setPercentage(calculatedPercentage);
			}
		} else {
			setError("");
			setPercentage(null);
		}
	};

	const applyFormula = (cgpa, totalCgpa) => {
		if (totalCgpa === 4) {
			// For CGPA out of 4
			if (cgpa >= 3.63) return ((cgpa - 0.3) / 0.037).toFixed(2);
			if (cgpa >= 3.25) return ((cgpa - 0.29) / 0.037).toFixed(2);
			if (cgpa >= 2.88) return ((cgpa - 0.36) / 0.036).toFixed(2);
			if (cgpa >= 2.5) return ((cgpa - 0.28) / 0.037).toFixed(2);
			if (cgpa >= 1.8) return ((cgpa + 1.65) / 0.069).toFixed(2);
			if (cgpa >= 1) return ((cgpa + 2.16) / 0.079).toFixed(2);
			if (cgpa > 0) return (cgpa / 0.0248).toFixed(2);
		} else if (totalCgpa === 5) {
			// For CGPA out of 5
			if (cgpa >= 4.63) return ((cgpa - 1.3) / 0.037).toFixed(2);
			if (cgpa >= 4.25) return ((cgpa - 1.29) / 0.037).toFixed(2);
			if (cgpa >= 3.88) return ((cgpa - 1.36) / 0.036).toFixed(2);
			if (cgpa >= 3.5) return ((cgpa - 1.28) / 0.037).toFixed(2);
			if (cgpa >= 2.8) return ((cgpa + 0.65) / 0.069).toFixed(2);
			if (cgpa >= 2) return ((cgpa + 1.16) / 0.079).toFixed(2);
			if (cgpa > 0) return ((cgpa - 1) / 0.0248).toFixed(2);
		}
		return 0;
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
			sx={{
				backgroundImage: `url(${image1})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				padding: 2,
				overflow: "hidden",
			}}
		>
			<Paper
				elevation={6}
				sx={{
					padding: 3,
					borderRadius: 3,
					maxWidth: 600,
					textAlign: "center",
					backgroundColor: "#ffffff",
					opacity: 0.9,
				}}
			>
				<Box mb={2}>
					<SchoolIcon sx={{ fontSize: 50, color: "#4caf50" }} />
				</Box>

				<Typography
					variant="h4"
					gutterBottom
					sx={{ color: "#2e7d32", fontWeight: "bold" }}
				>
					Online Convert CGPA to Percentage using HEC Formula in Pakistan 2024
				</Typography>

				<Divider sx={{ my: 3 }} />

				<Typography variant="body1" sx={{ color: "#455a64", mb: 4 }}>
					Easily convert your CGPA to a percentage using the official formula
					provided by the Higher Education Commission (HEC) Pakistan. This tool
					is designed to help you accurately represent your academic records,
					whether you're applying for higher education or job opportunities.
				</Typography>

				<Typography
					variant="h6"
					sx={{ color: "#2e7d32", fontWeight: "bold", mb: 1 }}
				>
					How to Convert CGPA to Percentage according to formula issued by HEC
					Pakistan
				</Typography>
				<Typography variant="body2" sx={{ color: "#455a64", mb: 1 }}>
					<ol>
						<li>
							Select the Scale: Choose between the 4.0 or 5.0 CGPA scale,
							depending on your educational system.
						</li>
						<li>Enter Your CGPA: Input your CGPA in the provided field.</li>
					</ol>
				</Typography>

				<FormControl component="fieldset" sx={{ marginBottom: 3 }}>
					<FormLabel
						component="legend"
						sx={{ color: "#1976d2", fontWeight: "bold" }}
					>
						<Typography variant="h6" sx={{ fontWeight: "bold" }}>
							Select Total CGPA
						</Typography>
					</FormLabel>
					<RadioGroup
						row
						aria-label="total-cgpa"
						name="total-cgpa"
						value={totalCgpa}
						onChange={handleTotalCgpaChange}
						sx={{ justifyContent: "center" }}
					>
						<FormControlLabel
							value={4}
							control={<Radio color="primary" />}
							label="4"
						/>
						<FormControlLabel
							value={5}
							control={<Radio color="primary" />}
							label="5"
						/>
					</RadioGroup>
				</FormControl>

				{error && (
					<Alert severity="error" sx={{ marginBottom: 3 }}>
						{error}
					</Alert>
				)}

				<TextField
					label="Enter CGPA"
					variant="outlined"
					value={cgpa}
					onChange={handleCgpaChange}
					fullWidth
					error={!!error}
					sx={{
						// marginBottom: 4,
						backgroundColor: "#e8f0fe",
						borderRadius: 1,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end">
									<CalculateIcon sx={{ color: "#1976d2" }} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				{percentage !== null && (
					<Paper
						elevation={3}
						sx={{
							padding: 3,
							marginTop: 4,
							backgroundColor: "#f8f9fa",
							marginBottom: 3,
						}}
					>
						<Typography
							variant="h5"
							sx={{ color: "#d32f2f", fontWeight: "bold" }}
						>
							Calculated Percentage: {percentage}%
						</Typography>
						<Typography
							variant="h6"
							sx={{ color: "#455a64", fontWeight: "bold" }}
						>
							CGPA Scored: {cgpa} / {totalCgpa}
						</Typography>
					</Paper>
				)}

				<Typography
					variant="h6"
					sx={{ color: "#2e7d32", fontWeight: "bold", mb: 2, mt: 2 }}
				>
					Why Use Our HEC CGPA to Percentage Conversion Tool?
				</Typography>
				<Typography variant="body2" sx={{ color: "#455a64", mb: 4 }}>
					<ul>
						<li>
							<strong>Official Formula Compliance:</strong> Uses the conversion
							standards recognized by HEC.
						</li>
						<li>
							<strong>Multiple Scales Supported:</strong> Supports both 4.0 and
							5.0 CGPA scales.
						</li>
						<li>
							<strong>Accurate Representation:</strong> Helps in translating
							academic performance into a percentage format for various
							applications.
						</li>
					</ul>
				</Typography>

				<Typography
					variant="h6"
					sx={{ color: "#2e7d32", fontWeight: "bold", mb: 2 }}
				>
					Common Searches Related to HEC CGPA Conversion:
				</Typography>
				<Typography variant="body2" sx={{ color: "#455a64", mb: 4 }}>
					<ul>
						<li>
							<strong>HEC CGPA to Percentage Calculator:</strong> Find out how
							to convert your CGPA to a percentage for HEC guidelines.
						</li>
						<li>
							<strong>CGPA to Percentage HEC:</strong> Learn the conversion
							formula and easily calculate your percentage.
						</li>
						<li>
							<strong>HEC Percentage to CGPA:</strong> Understand how to convert
							percentages back to CGPA.
						</li>
						<li>
							<strong>Convert CGPA to Percentage HEC Pakistan:</strong> Use our
							tool designed specifically for HEC Pakistan standards.
						</li>
						<li>
							<strong>How to Convert CGPA into Percentage out of 4 HEC:</strong>{" "}
							Follow the steps for a 4.0 CGPA scale conversion.
						</li>
					</ul>
					With our easy-to-use calculator, you can confidently convert your CGPA
					to percentage according to HEC standards and enhance the accuracy of
					your academic applications.
				</Typography>

				<Divider sx={{ my: 4 }} />
				<Typography variant="body2" sx={{ color: "#757575", marginTop: 2 }}>
					Developed by{" "}
					<Link
						href="https://www.linkedin.com/in/usama-bhatti-4061/"
						target="_blank"
						rel="noopener"
						sx={{
							color: "#1976d2",
							fontWeight: "bold",
							textDecoration: "none",
							"&:hover": { textDecoration: "underline" },
						}}
					>
						Usama Bhatti
					</Link>
				</Typography>
			</Paper>
		</Box>
	);
}

export default App;
